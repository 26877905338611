<template>
  <div v-if="showSent" class="gray p-md-3 py-4 py-md-5">
    <div class="row">
      <div class="col-12">
        <div class="row mb-4 mb-md-3 d-flex align-items-center">
          <div class="col-9">
            <h4><span v-if="showSent && orderDetails.order_id"> Order summary: {{ orderDetails.order_id }}</span></h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-for="product in cartProducts"
                 v-bind:key="product.productId + '-' + product.leaseProductId + '-' + product.variantId">
              <ProductSummaryRow v-bind:cartItem=product />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-0 mt-md-1"></div>
          <div class="col-6 col-md-12 mt-md-1">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="mb-0 price d-block d-md-none">Selected carrier service(s)</div>
                <div class="mb-0 font-weight-bold d-none d-md-block">Selected carrier service(s)</div>
              </div>
              <!--NEEDED LATER MAYBE <div v-if=hasSelectedRates class="col-md-3 d-block d-md-none">
                {{ shippingTotal.toFixed(2) }}{{ $store.getters.currencyStr }}
              </div>
              <div v-if=hasSelectedRates
                   class="col-md-3 text-md-right font-weight-bold d-none d-md-block">
                {{ shippingTotal.toFixed(2) }}{{ $store.getters.currencyStr }}
              </div>
              <div v-else class="col-md-3 text-md-right font-weight-bold d-none d-md-block">
                N/A
              </div>-->
            </div>

            <div v-if="hasSelectedRates">
              <div v-for="item in selectedRates"
                   :key="!Object.values(item)[0].selectedRate ? null:Object.values(item)[0].selectedRate.rate_id"
              >
                <div class="row" v-if="Object.values(item)[0].selectedRate">
                  <div class="col-md-9">
                    <div class="mb-0 price d-block d-md-none">
                      {{ Object.values(item)[0].selectedRate.carrier_label }}
                      {{ Object.values(item)[0].selectedRate.service }}
                    </div>
                    <div class="mb-0 d-none d-md-block">
                      {{ carrierName(Object.values(item)[0].selectedRate.carrier_label) }}
                      {{ Object.values(item)[0].selectedRate.service }}
                    </div>
                  </div>
                  <!--NEEDED LATER MAYBE <div class="col-md-3 d-block d-md-none">
                    {{ Object.values(item)[0].selectedRate.rate }}{{ $store.getters.currencyStr }}
                  </div>
                  <div class="col-md-3 text-md-right d-none d-md-block">
                    {{ Object.values(item)[0].selectedRate.rate }}{{ $store.getters.currencyStr }}
                  </div>-->
                </div>
              </div>
            </div>
            <div v-else class="col-md-9">
              <div class="d-block d-md-none">N/A</div>
              <div class="d-none d-md-block">N/A</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 col-md-0 mt-md-1"></div>
          <div class="col-6 col-md-12 mt-md-4">
            <div class="row">
              <div class="col-12 col-md-9">
                <div class="mb-0 price d-block d-md-none">Total</div>
                <div class="mb-0 font-weight-bold d-none d-md-block">Total</div>
              </div>
              <div class="col-12 d-block d-md-none">{{ $store.getters.cartItemCount }} items</div>
              <div class="col-md-3 d-block d-md-none">
                {{ cartTotal }}{{ $store.getters.currencyStr }}
              </div>
              <div class="col-md-3 text-md-right font-weight-bold d-none d-md-block">
                {{ cartTotal }}{{ $store.getters.currencyStr }}
              </div>
            </div>
            <div class="row">
              <div class="col-md-9">
                <div class="d-block d-md-none">VAT 0%</div>
                <div class="d-none d-md-block">VAT (0%)</div>
              </div>
              <div class="col-md-3 text-right d-none d-md-block">
                0.00{{ $store.getters.currencyStr }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <h4>Delivery details</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="price d-block d-md-none font-weight-bold">Delivery address</div>
            <div class="d-none d-md-block font-weight-bold">Delivery address</div>
          </div>
          <div class="col-md-6">
            <div>{{ selectedAddress.name }}</div>
            <div>{{ orderDetails.recipient_name }}</div>
            <div>{{ selectedAddress.address }} {{ selectedAddress.address_extra }}</div>
            <div></div>
            <div>{{ selectedAddress.zip_code }} {{ selectedAddress.city }}</div>
            <div v-if="selectedAddress.country">{{ selectedAddress.country.name }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="price d-block d-md-none font-weight-bold">Delivery notes</div>
            <div class="d-none d-md-block font-weight-bold">Delivery notes</div>
          </div>
          <div class="col-md-6">
            <div>{{ orderDetails.info != null && orderDetails.info != "" ? orderDetails.info : "--" }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="price d-block d-md-none font-weight-bold">Recipient phone number</div>
            <div class="d-none d-md-block font-weight-bold">Recipient phone number</div>
          </div>
          <div class="col-md-6">
            <div>{{ orderDetails.phone != null && orderDetails.phone != "" ? orderDetails.phone : "--" }}</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="price d-block d-md-none font-weight-bold">Send tracking code to</div>
            <div class="d-none d-md-block font-weight-bold">Send tracking code to</div>
          </div>
          <div class="col-md-6">
            <div>{{ userEmail != null && userEmail !== "" ? userEmail : "--" }}</div>
          </div>
        </div>
        <div class="row" v-if="showAll">
          <div class="col-12 mt-2" v-if="orderDetails.attachment">
            <div class="row">
              <div class="col-md-6">
                <div class="price d-block d-md-none font-weight-bold">Attachment</div>
                <div class="d-none d-md-block font-weight-bold">Attachment</div>
              </div>
              <div class="col-md-6">
                <div><a :href="orderDetails.attachment" target="_blank">{{
                    getFileName(orderDetails.attachment)
                  }}</a></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <h4>Cost center or PO number</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-md-6">
                <div class="price d-block d-md-none">Cost center</div>
                <div class="d-none d-md-block">Cost center</div>
              </div>
              <div class="col-md-6">
                <div>{{ selectedCC.name != null && selectedCC.name != "" ? selectedCC.name : "--" }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-12">
        <div class="row mb-4 mb-md-3 d-flex align-items-center">
          <div class="col-9">
            <h4><span v-if="showSent && orderDetails.order_id"> Order: {{ orderDetails.order_id }}</span></h4>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div v-for="product in cartProducts"
                 v-bind:key="product.productId + '-' + product.leaseProductId + '-' + product.variantId">
              <ProductSummaryRow v-bind:cartItem=product />
            </div>
            <!-- NEEDED LATER MAYBE <div class="row">
              <div class="col-6 col-md-0 mt-md-4"></div>
              <div class="col-6 col-md-12 mt-md-4">
                <div class="row">
                  <div class="col-12 col-md-9">
                    <div class="mb-0 price d-block d-md-none">Estimated delivery costs</div>
                    <div class="mb-0 font-weight-bold d-none d-md-block">Estimated delivery costs</div>
                  </div>
                  <div v-if=hasSelectedRates class="col-md-3 d-block d-md-none">
                    {{ shippingTotal.toFixed(2) }}{{ $store.getters.currencyStr }}
                  </div>
                  <div v-if=hasSelectedRates
                       class="col-md-3 text-md-right font-weight-bold d-none d-md-block">
                    {{ shippingTotal.toFixed(2) }}{{ $store.getters.currencyStr }}
                  </div>
                  <div v-else class="col-md-3 text-md-right font-weight-bold d-none d-md-block">
                    N/A
                  </div>
                </div>

                <div v-if="hasSelectedRates">
                  <div v-for="item in selectedRates"
                       :key="!Object.values(item)[0].selectedRate ? null:Object.values(item)[0].selectedRate.rate_id"
                  >
                    <div class="row" v-if="Object.values(item)[0].selectedRate">
                      <div class="col-md-9">
                        <div class="mb-0 price d-block d-md-none">
                          {{ Object.values(item)[0].selectedRate.carrier_label }}
                          {{ Object.values(item)[0].selectedRate.service }}
                        </div>
                        <div class="mb-0 d-none d-md-block">
                          {{ carrierName(Object.values(item)[0].selectedRate.carrier_label) }}
                          {{ Object.values(item)[0].selectedRate.service }}
                        </div>
                      </div>
                      <div class="col-md-3 d-block d-md-none">
                        {{ Object.values(item)[0].selectedRate.rate }}{{ $store.getters.currencyStr }}
                      </div>
                      <div class="col-md-3 text-md-right d-none d-md-block">
                        {{ Object.values(item)[0].selectedRate.rate }}{{ $store.getters.currencyStr }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-9">
                  <div class="d-block d-md-none">N/A</div>
                  <div class="d-none d-md-block">N/A</div>
                </div>
              </div>
            </div>-->
            <div class="row">
              <div class="col-6 col-md-0 mt-md-4"></div>
              <div class="col-6 col-md-12 mt-md-4">
                <div class="row">
                  <div class="col-12 col-md-9">
                    <div class="mb-0 price d-block d-md-none">Total</div>
                    <div class="mb-0 font-weight-bold d-none d-md-block">Total</div>
                  </div>
                  <div class="col-12 d-block d-md-none">{{ $store.getters.cartItemCount }} items</div>
                  <div class="col-md-3 d-block d-md-none">
                    {{ cartTotal }}{{ $store.getters.currencyStr }}
                  </div>
                  <div class="col-md-3 text-md-right font-weight-bold d-none d-md-block">
                    {{ cartTotal }}{{ $store.getters.currencyStr }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-9">
                    <div class="d-block d-md-none">VAT 0%</div>
                    <div class="d-none d-md-block">VAT (0%)</div>
                  </div>
                  <div class="col-md-3 text-right d-none d-md-block">
                    0.00{{ $store.getters.currencyStr }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-2">
                    <h4>Cost center or PO number</h4>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="price d-block d-md-none">Cost center</div>
                        <div class="d-none d-md-block">Cost center</div>
                      </div>
                      <div class="col-md-6">
                        <div>{{ selectedCC.name != null && selectedCC.name != "" ? selectedCC.name : "--" }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="price d-block d-md-none font-weight-bold">Delivery address</div>
                <div class="d-none d-md-block font-weight-bold">Delivery address</div>
              </div>
              <div class="col-md-6">
                <div>{{ selectedAddress.name }}</div>
                <div>{{ orderDetails.recipient_name }}</div>
                <div>{{ selectedAddress.address }}</div>
                <div>{{ selectedAddress.address_extra }}</div>
                <div>{{ selectedAddress.zip_code }} {{ selectedAddress.city }}</div>
                <div v-if="selectedAddress.country">{{ selectedAddress.country.name }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="price d-block d-md-none font-weight-bold">Delivery notes</div>
                    <div class="d-none d-md-block font-weight-bold">Delivery notes</div>
                  </div>
                  <div class="col-md-6">
                    <div>{{ orderDetails.info != null && orderDetails.info != "" ? orderDetails.info : "--" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="price d-block d-md-none font-weight-bold">Recipient phone number</div>
                    <div class="d-none d-md-block font-weight-bold">Recipient phone number</div>
                  </div>
                  <div class="col-md-6">
                    <div>{{ orderDetails.phone != null && orderDetails.phone != "" ? orderDetails.phone : "--" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mt-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="price d-block d-md-none font-weight-bold">Send tracking code to</div>
                    <div class="d-none d-md-block font-weight-bold">Send tracking code to</div>
                  </div>
                  <div class="col-md-6">
                    <div>{{ userEmail != null && userEmail !== "" ? userEmail : "--" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="showAll">
              <div class="col-12 mt-2" v-if="orderDetails.attachment">
                <div class="row">
                  <div class="col-md-6">
                    <div class="price d-block d-md-none font-weight-bold">Attachment</div>
                    <div class="d-none d-md-block font-weight-bold">Attachment</div>
                  </div>
                  <div class="col-md-6">
                    <div><a :href="orderDetails.attachment" target="_blank">{{
                        getFileName(orderDetails.attachment)
                      }}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-block d-md-none">
      <div class="col-12 mt-2">
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>

import ProductSummaryRow from './ProductSummaryRow'

export default {
  name: 'OrderSummary',
  data() {
    return {
      localSelectedRates: [],
      carriersInfo: null
    }
  },
  props: {
    showAll: {
      type: Boolean,
      default: false
    },
    showSent: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: true
    },
  },
  components: {
    ProductSummaryRow
  },
  computed: {
    cartProducts() {
      const items = this.$store.getters.cartItems;
      if (this.$store.getters.cartLeaseItems && this.$store.getters.cartLeaseItems.length > 0) {
        return items.concat(this.$store.getters.cartLeaseItems);
      }
      if (this.showSent) {
        if (this.$store.getters.sentLeaseItems && this.$store.getters.sentLeaseItems.length > 0) {
          return this.$store.getters.sentItems.concat(this.$store.getters.sentLeaseItems)
        } else {
          return this.$store.getters.sentItems;
        }
      }
      return items;
    },
    cartTotal() {
      const cartTotal = (parseFloat(this.$store.getters.cartTotal)).toFixed(2);
      //const cartTotal = (this.shippingTotal + parseFloat(this.$store.getters.cartTotal)).toFixed(2);
      const sentTotal = (parseFloat(this.$store.getters.sentTotal)).toFixed(2);
      //const sentTotal = (this.shippingTotal + parseFloat(this.$store.getters.sentTotal)).toFixed(2);
      return this.showSent ? sentTotal : cartTotal;
    },
    shippingTotal() {
      let totalRates = 0;
      this.selectedRates.forEach(item => {
        const supplierKey = Object.keys(item)[0]
        if (Object.keys(item[supplierKey]).length !== 0) {
          const rate = item[supplierKey].selectedRate.rate;
          totalRates += rate;
        }
      });
      return totalRates;
    },
    selectedAddress() {
      const address = this.$store.getters.sentOrder.address ? this.$store.getters.sentOrder.address : this.$store.getters.orderDetails.address;
      const addr = this.$store.getters.addresses.find(a => a.id == address);
      if (!addr) {
        return {}
      }
      return addr;
    },
    orderDetails() {
      if (this.showSent) {
        return this.$store.getters.sentOrder;
      }
      return this.$store.getters.orderDetails;
    },
    selectedCC() {
      const ccid = this.$store.getters.sentOrder.cc ? this.$store.getters.sentOrder.cc : this.$store.getters.orderDetails.cc;
      const cc = this.$store.getters.costcenters.find(c => c.id == ccid);
      if (!cc) {
        return {}
      }
      return cc;
    },
    userEmail() {
      return this.$store.getters.profile.user.email;
    },
    selectedRates() {
      return this.$store.getters.selectedRates
    },
    hasSelectedRates() {
      return this.selectedRates.some(item => {
        const selectedRate = Object.values(item)[0].selectedRate;
        return selectedRate && Object.keys(selectedRate).length !== 0;
      });
    }
  },
  watch: {
    selectedRates: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.localSelectedRates = [...newVal];
        }
      },
      deep: true,
      immediate: true
    }
  },
  async created() {
    this.carriersInfo = await this.fetchCarriersInfo();
  },
  methods: {
    async fetchCarriersInfo() {
      try {
        let carriers = await this.getCarrierInfo();
        return carriers
      } catch (e) {
        return null
      }
    },
    async getCarrierInfo() {
      if (!this.$store.getters.carriersInfoFetched) {
        if (!this.$store.state.carriersFetched) {
          this.$store.dispatch('fetchCarriers');
        }
        if (!this.$store.state.storeCarriersFetched) {
          await this.$store.dispatch('fetchStoreCarriers');
        }
      }
      const carrier = this.$store.getters.carriersInfo
      return carrier
    },
    carrierName(label) {
      if (!this.carriersInfo) {
        return ''
      } else {
        const carrier = this.carriersInfo[label]
        return carrier ? carrier.name : ''
      }
    },
    getFileName(url) {
      return url.split("/").pop();
    },
    hasNonEmptyObject(arr) {
      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          const obj = arr[i];
          const keys = Object.keys(obj);
          const key = keys[0];
          const value = obj[key];

          if (value && Object.keys(value).length !== 0) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    }
  }
}
</script>
