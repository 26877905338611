<template>
  <div>
    <div class="row mb-0 mb-md-3">
      <div class="col-md-9">
        <div class="row">
          <div class="col-6 col-md-2">
            <img v-if="!product.primary_thumb_small" src="@/assets/images/product-photo-coming-soon-small.jpg" class="product-card-image" alt="">
            <div v-else>
              <img width="50" :src="imageUrl" class="product-card-image d-none d-md-inline" alt="">
              <img :src="mobileImageUrl" class="product-card-image d-md-none w-100" alt="">
            </div>
          </div>
          <div class="col-6 col-md-10">
            <div class="font-weight-bold">{{ getProductName }}</div>
            <div v-if="variant">
              <div class="price" v-if="variant.sku">SKU: {{variant.sku}}</div>
            </div>
            <div v-else-if="product.ext_product_id" style="color: #9a9a9a;">SKU: {{ product.ext_product_id }}</div>
            <div class="price" v-if="isLeaseProduct">Lease product</div>
            <div v-if="!isLeaseProduct" class="d-md-none d-flex align-items-center">{{ product.price + $store.getters.currencyStr + " per item" }}</div>
            <div class="d-block d-md-none">
              <div>x{{ cartItem.count }}</div>
              <div class="price mt-3">Subtotal</div>
              <div>{{ isLeaseProduct ? '0'+$store.getters.currencyStr : total+$store.getters.currencyStr }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 d-none d-md-block">
        <div class="text-right">{{ isLeaseProduct ? '' : product.price+$store.getters.currencyStr }}</div>
        <div class="text-right">x{{ cartItem.count }}</div>
      </div>
    </div>
    <div class="row d-flex d-md-none">
      <div class="col-12">
        <hr class="my-4" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProductSummaryRow',
  props: {
    cartItem: Object,
  },

  computed: {
    product(){
      if(this.$store.state.cartProductObjects.length > 0 && !this.isLeaseProduct){
        return this.$store.state.cartProductObjects.find(p => p.id == this.cartItem.productId)
      } else if(this.$store.state.cartLeaseProductObjects.length > 0 && this.isLeaseProduct){
        return this.$store.state.cartLeaseProductObjects.find(p => p.id == this.cartItem.leaseProductId)
      } else {
        return {}
      }
    },
    total(){
      return (this.product.price * this.cartItem.count).toFixed(2);
    },
    imageUrl () {
      const path = this.product.primary_thumb_small;
      if(path && path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
    mobileImageUrl(){
      const path = this.product.primary_thumb_big;
      if(path && path.startsWith(process.env.VUE_APP_IMAGE_HOST)){
        return path;
      } else {
        return process.env.VUE_APP_IMAGE_HOST + path;
      }
    },
    variant() {
      if(this.cartItem.variantId && this.product.variants){
        const variant = this.product.variants.find(v => v.id == this.cartItem.variantId)
        return variant;
      }
      return null;
    },
    getProductName(){
      if(this.variant && this.product.variants.length > 1){
        return this.product.name + " - " + this.variant.name;
      } else {
        return this.product.name;
      }
    },
    isLeaseProduct(){
      return this.cartItem.hasOwnProperty('leaseProductId')
    }
  },
}
</script>